import axios from "axios";
// import service from 'services';
import service from "..";
import routes from "../routes";
import { auth } from "./auth";

const accessToken = service.getTokensLocalStorage()?.access?.token;
const token = service.getTokensLocalStorage();

axios.interceptors.request.use((config) => {
  if (accessToken) config.headers["Authorization"] = `Bearer ${accessToken}`;
  return config;
}, Promise.reject);

axios.interceptors.response.use(
  (res) => res,
  async (err) => {
    const originalConfig = err?.config;
    const instance = axios.create();

    try {
      if (
        err.response?.data?.status === 401 &&
        ["jwt expired", ""].includes(err.response?.data?.result) &&
        !originalConfig._retry
      ) {
        originalConfig._retry = true;
        const response = await auth.refreshToken(token?.refresh?.token);

        token.access = response?.data?.result?.access;

        service.removeTokensLocalStorage();
        service.setTokensLocalStorage(token);

        originalConfig.headers[
          "Authorization"
        ] = `Bearer ${response?.data?.result?.access?.token}`;

        return instance(originalConfig);
      }
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.reject(err?.response);
  }
);

const api = {
  uploadPicture: async (postBody) => {
    return await axios.post(routes.profilepic(), postBody);
  },

  // talents
  getTalents: async (queryParams, pageNumber = 1, pageSize = 20) => {
    return await axios.get(routes["get-talents"](), {
      params: { pageNumber, pageSize, ...queryParams },
    });
  },
  getTalent: async (id) => {
    return await axios.get(routes.talent(id));
  },
  deleteTalent: async (id) => {
    return await axios.delete(routes.talent(id));
  },
  actionTalent: async (id, action) => {
    return await axios.post(routes["talent-actions"](id, action));
  },
  getAppliedJobs: async (queryParams, pageNumber = 1, pageSize = 20) => {
    return await axios.get(routes["job-applicants"](), {
      params: { pageNumber, pageSize, ...queryParams },
    });
  },
  getTalentsProjects: async (
    id,
    queryParams,
    pageNumber = 1,
    pageSize = 20
  ) => {
    return await axios.get(routes["talents-projects"](id), {
      params: { pageNumber, pageSize, ...queryParams },
    });
  },
  exportTalents: async () => {
    return await axios.get(routes["export-talents"]());
  },

  // recruiters
  getRecuriters: async (queryParams, pageNumber = 1, pageSize = 20) => {
    return await axios.get(routes["get-recruiters"](), {
      params: { pageNumber, pageSize, ...queryParams },
    });
  },
  actionRecuriter: async (id, action) => {
    return await axios.post(routes["recruiter-actions"](id, action));
  },
  getRecuriter: async (id) => {
    return await axios.get(routes.recruiter(id));
  },
  deleteRecuriter: async (id) => {
    return await axios.delete(routes.recruiter(id));
  },
  exportRecruiters: async () => {
    return await axios.get(routes["export-recruiters"]());
  },

  // jobs
  getJobs: async (queryParams, pageNumber = 1, pageSize = 20) => {
    return await axios.get(routes["get-jobs"](), {
      params: { pageNumber, pageSize, ...queryParams },
    });
  },
  deleteJob: async (id) => {
    return await axios.delete(routes.job(id));
  },
  VerifyJob: async (id) => {
    return await axios.patch(routes.job(id));
  },
  actionJob: async (id, action) => {
    return await axios.post(routes["job-action"](id, action));
  },
  declineJob: async (id, postBody) => {
    return await axios.post(routes["job-action"](id, "decline"), postBody);
  },
  getJobTitles: async (pageNumber = 1, pageSize = 100) => {
    return await axios.get(routes["job-titles"](), {
      params: { pageNumber, pageSize },
    });
  },
  getJob: async (id) => {
    return await axios.get(routes.job(id));
  },
  getJobApplicants: async (queryParams, pageNumber = 1, pageSize = 20) => {
    return await axios.get(routes["job-applicants"](), {
      params: { pageNumber, pageSize, ...queryParams },
    });
  },
  getReportedJobs: async (queryParams, pageNumber = 1, pageSize = 7) => {
    return await axios.get(routes["reported-jobs"](), {
      params: { pageNumber, pageSize, ...queryParams },
    });
  },
  resolveReportedJob: async (id) => {
    return await axios.post(routes["resolve-jobReport"](id));
  },

  // Admin
  getAdmin: async () => {
    return await axios.get(routes["get-admin"]());
  },
  getAdmins: async (queryParams, pageNumber = 1, pageSize = 20) => {
    return await axios.get(routes["get-admins"](), {
      params: { pageNumber, pageSize, ...queryParams },
    });
  },
  adminById: async (id) => {
    return await axios.get(routes["admin-by-id"](id));
  },
  actionAdmin: async (id, action) => {
    return await axios.post(routes["admin-actions"](id, action));
  },
  createAdmin: async (adminType, postBody) => {
    return await axios.post(routes["create-admin"](adminType), postBody);
  },
  makeAdmin: async (id, adminType) => {
    return await axios.post(routes["make-admin"](id, adminType));
  },
  exportAdmin: async () => {
    return await axios.get(routes["export-admin"]());
  },

  // audit trail
  getTrail: async (queryParams, pageNumber = 1, pageSize = 10) => {
    return await axios.get(routes["get-audit-trail"](), {
      params: { pageNumber, pageSize, ...queryParams },
    });
  },
  getTrailOverview: async () => {
    return await axios.get(routes["get-audit-overview"]());
  },

  // skills
  getSkills: async (page = 1, limit = 800) => {
    return await axios.get(routes["get-skills"](), { params: { page, limit } });
  },
  deleteSkill: async (name) => {
    return await axios.delete(routes["delete-skill"](name));
  },

  getFeedbacks: async (queryParams, pageNumber = 1, pageSize = 7) => {
    return await axios.get(routes.feedbacks(), {
      params: { pageNumber, pageSize, ...queryParams },
    });
  },
  resolveFeedback: async (id) => {
    return await axios.post(routes["resolve-feedback"](id));
  },
  // export endpoints

  exportAuditTrail: async (queryParams, pageNumber = 1, pageSize = 7) => {
    return await axios.get(routes["audit-export"](), {
      params: { pageNumber, pageSize, ...queryParams },
    });
  },

  // conversations
  getConversations: async (pageNumber = 1, pageSize = 15) => {
    return await axios.get(routes["get-conversations"](), {
      params: { pageNumber, pageSize },
    });
  },
  getTalentsChatHistory: async (id, pageNumber = 1, pageSize = 15) => {
    return await axios.get(routes["get-talent-chat-history"](id), {
      params: { pageNumber, pageSize },
    });
  },
  getRecruitersChatHistory: async (id, pageNumber = 1, pageSize = 15) => {
    return await axios.get(routes["get-recruiter-chat-history"](id), {
      params: { pageNumber, pageSize },
    });
  },
  getMessages: async (id, reader, pageNumber = 1, pageSize = 15) => {
    return await axios.get(routes["get-messages"](id), {
      params: { pageNumber, pageSize, reader },
    });
  },
  createCustomMessage: async (postBody) => {
    return await axios.post(routes["custom-messages"](), postBody);
  },
  getCustomMessages: async () => {
    return await axios.get(routes["custom-messages"]());
  },
  deleteCustomMessages: async (id) => {
    return await axios.delete(routes["delete-custom-messages"](id));
  },

  setReferralsPercentage: async (postBody) => {
    return await axios.post(routes["referrals-percentage"](), postBody);
  },
  getReferralsPercentage: async () => {
    return await axios.get(routes["referrals-percentage"]());
  },
  getReferrals: async (queryParams, page = 1, limit = 10,) => {
    return await axios.get(routes["get-referrals"](), {
      params: { page, limit, ...queryParams },
    });
  },

  createPromo: async (postBody) => {
    return await axios.post(routes.promos(), postBody)
  },
  getPromos: async (queryParams, page = 1, limit = 10,) => {
    return await axios.get(routes.promos(), {
      params: { page, limit, ...queryParams },
    })
  },
  activatePromo: async (id) => {
    return await axios.post(routes["activate-promo"](id))
  },
  deactivatePromo: async (id) => {
    return await axios.post(routes["deactivate-promo"](id))
  },
  deactivateAllPromo: async () => {
    return await axios.post(routes["deactivate-all-promo"]())
  },
  getPromoUsage: async (id) => {
    return await axios.get(routes["get-promo-usage"](id))
  },


  addBadge: async (postBody) => {
    return await axios.post(routes.badge(), postBody)
  },
  updateBadge: async (postBody) => {
    return await axios.put(routes.badge(), postBody)
  },
  getBadges: async (queryParams, page = 1, limit = 10,) => {
    return await axios.get(routes.badge(), {
      params: { page, limit, ...queryParams },
    })
  },
  deleteBadge: async (id) => {
    return await axios.delete(routes["delete-badge"](id))
  },
  awardBadge: async (postBody) => {
    return await axios.post(routes["award-badge"](), postBody)
  },
  getBadgeAward: async (id) => {
    return await axios.get(routes["get-award-badges"](id))
  },
  getBadgeTitles: async () => {
    return await axios.get(routes["get-award-titles"]())
  },


  // advert room
  getRooms: async () => {
    return await axios.get(routes.rooms())
  },
  uploadImageSpace: async (id,postBody) => {
    return await axios.post(routes["upload-image-for-space"](id),postBody)
  },
  getRoomSpaces: async (id) =>{
    return await axios.get(routes["room-spaces"](id))
  },
  deleteSpaceImage: async (id,slot)=>{
    return await axios.delete(routes["delete-space-image"](id,slot))
  },

  // subscriptions
  createSub : async (postBody) => {
    return await axios.post(routes["subscription"](),postBody)
  },
  getSubs: async () => {
    return await axios.get(routes["admin-get-subscriptions"]())
  },
};

export default api;
