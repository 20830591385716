import React from "react";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { InputAdornment } from "@mui/material";
import { CopyAllTwoTone } from "@mui/icons-material";
import "./css/InputField.css";

const InputField = ({
  name,
  value,
  type,
  onChange,
  placeholder,
  title,
  helperText,
  isReadOnly = false,
  variant = "standard",
  className,
  multiline,
  rows,
  isSelect = false,
  selectOptions = [],
  disabled = false,
}) => {
  return (
    <div>
      <label className="input_label">{title}</label>
      {isSelect ? (
        <Select
          name={name}
          value={value}
          onChange={onChange}
          variant={variant}
          fullWidth
          className={className}
          displayEmpty
          inputProps={{
            readOnly: isReadOnly,
          }}
          sx={{
            textTransform:'capitalize',
            padding: "10px 14px",
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
              {
                borderColor: "black",
              },
            "& .MuiOutlinedInput-root": {
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "black",
              },
            },
          }}
        >
          <MenuItem value="">Select all that apply</MenuItem>
          {selectOptions.map((option, index) => (
            <MenuItem  key={index} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <TextField
          name={name}
          id="standard-basic"
          variant={variant}
          value={value}
          type={type}
          onChange={onChange}
          placeholder={placeholder}
          fullWidth
          disabled={disabled}
          multiline={multiline}
          rows={rows}
          helperText={helperText}
          className={className}
          slotProps={{
            input: {
              endAdornment: (
                <InputAdornment position="end">
                  <CopyAllTwoTone />
                </InputAdornment>
              ),
            },
          }}
          sx={{
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
              {
                borderColor: "black",
              },
            "& .MuiOutlinedInput-root": {
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "black",
              },
            },
          }}
        />
      )}
    </div>
  );
};

export default InputField;
